import _ from 'underscore';
import { FileUpload } from './FileUpload';
import Sortable from 'sortablejs';
import { SaveForm } from './SaveForm';

export class AdminProducts {
    protected productTemplate:any
    protected sectionTemplate:any

    protected $listSections?:Element
    protected saveForm:SaveForm;

    init() {
        this.$listSections = document.getElementById('listSections') || undefined;

        if(!this.$listSections) return;

        this.saveForm = new SaveForm('form-edit-products');

        document.addEventListener('mousedown', this.handleDocumentMouseDown.bind(this));
        document.addEventListener('touchstart', this.handleDocumentMouseDown.bind(this));

        document.querySelectorAll('.productItem').forEach(elem => {
            elem.querySelectorAll('input').forEach(elem2 => elem2.addEventListener('click', this.handleEditProductClick.bind(this)));
            elem.querySelector('.btn-save-product')?.addEventListener('click', this.handleSaveProductClick.bind(this));
            elem.querySelector('.btn-delete-product')?.addEventListener('click', this.handleDeleteProductClick.bind(this));
            elem.querySelector('.cancelDeleted')?.addEventListener('click', this.handleCancelDeleteProductClick.bind(this));
            elem.querySelector('input[name="product-hidden-checkbox[]"]')?.addEventListener('change', this.handleProductHiddenChange.bind(this));
            elem.querySelector('input[name="product-new-checkbox[]"]')?.addEventListener('change', this.handleProductNewChange.bind(this));
            FileUpload(elem.querySelector('input[name="product-image[]') as HTMLInputElement, () => this.saveForm.setModified(true));
        });

        document.querySelectorAll('.sectionItem').forEach(elem => {
            elem.querySelector('.btn-save-section')?.addEventListener('click', this.handleSaveSectionClick.bind(this));
            elem.querySelector('.btn-delete-section')?.addEventListener('click', this.handleDeleteSectionClick.bind(this));
            elem.querySelector('.cancelDeleted')?.addEventListener('click', this.handleCancelDeleteSectionClick.bind(this));
            elem.querySelector('.btn-add-product')?.addEventListener('click', this.handleAddProductClick.bind(this));
            elem.querySelector('.titletItem .edit')?.addEventListener('click', this.handleEditSectionClick.bind(this));
            elem.querySelector('.titletItem input[name="section-name[]"]')?.addEventListener('change', this.handleEditSectionTitleChange.bind(this)); 

            this.initSortableProducts(elem.querySelector('.listItems') || undefined);
        });

        this.initSortableSections();

        document.getElementById('btn-add-section')?.addEventListener('click', this.handleAddSectionClick.bind(this));

        this.productTemplate = _.template(document.getElementById('product-template')?.innerHTML || '');
        this.sectionTemplate = _.template(document.getElementById('section-template')?.innerHTML || '');
    }

    initSortableSections() {
        Sortable.create(this.$listSections, {
            animation: 150,
            handle: '.moveSectionItem',
            scrollSensitivity: 200,
            scrollSpeed: 20,
            forceFallback: true,
            onUpdate: (e) => {
                this.saveForm.setModified(true);
                this.$listSections?.querySelectorAll('.sectionItem').forEach((sectionElem, i) => {
                    let inputIndex = sectionElem.querySelector('input[name="section-index[]"]') as HTMLInputElement|undefined;
                    if(inputIndex) {
                        inputIndex.value = i.toString();
                    }

                    sectionElem.querySelectorAll('.productItem').forEach((productElem, j) => {
                        let inputSectionIndex = productElem.querySelector('input[name="product-section-index[]"]') as HTMLInputElement|undefined;
                        if(inputSectionIndex) {
                            inputSectionIndex.value = i.toString();
                        }
                    });
                });

                this.$listSections?.querySelectorAll('.productItem').forEach((productElem, j) => {
                    let inputProductIndex = productElem.querySelector('input[name="product-index[]"]') as HTMLInputElement|undefined;
                    if(inputProductIndex) {
                        inputProductIndex.value = j.toString();
                    }   
                });
            }
        });
    }

    initSortableProducts(elem?:Element) {
        if(!elem) return;
        
        Sortable.create(elem, {
            group: 'products',
            animation: 150,
            handle: '.moveProductItem',
            scrollSensitivity: 200,
            scrollSpeed: 20,
            forceFallback: true,
            onSort: (e) => {
                this.saveForm.setModified(true);
                this.$listSections?.querySelectorAll('.sectionItem').forEach((sectionElem, i) => {
                    sectionElem.querySelectorAll('.productItem').forEach((productElem, j) => {
                        let inputSectionIndex = productElem.querySelector('input[name="product-section-index[]"]') as HTMLInputElement|undefined;
                        if(inputSectionIndex) {
                            inputSectionIndex.value = i.toString();
                        }
                    });
                });

                this.$listSections?.querySelectorAll('.productItem').forEach((productElem, j) => {
                    let inputProductIndex = productElem.querySelector('input[name="product-index[]"]') as HTMLInputElement|undefined;
                    if(inputProductIndex) {
                        inputProductIndex.value = j.toString();
                    }   
                });
            },
        });
    }

    handleDocumentMouseDown(e:Event) {
        document.querySelectorAll('.titletItem').forEach(elem2 => {
            //Skip if we're selecting a section
            if(elem2 === e.target || (e.target instanceof Node && elem2.contains(e.target))) {
                return;
            }

            elem2.classList.remove('editing');
        });

        document.querySelectorAll('.productItem').forEach(elem2 => {
            //Skip if we're selecting an item
            if(elem2 === e.target || (e.target instanceof Node && elem2.contains(e.target))) {
                return;
            }

            elem2.classList.remove('editing');
        });
    }

    handleEditSectionClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        e.target.closest('.titletItem')?.classList.add('editing');
    }

    handleSaveSectionClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        e.target.closest('.titletItem')?.classList.remove('editing');
    }

    handleDeleteSectionClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        const sectionElem = e.target.closest('.sectionItem');
        const inputElem = sectionElem?.querySelector('input[name="section-deleted[]"]') as HTMLInputElement|undefined;

        if(inputElem) {
            inputElem.value = '1';
            sectionElem?.classList.add('deleted');
            this.saveForm.setModified(true);
        }
    }

    handleCancelDeleteSectionClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        const sectionElem = e.target.closest('.sectionItem');
        const inputElem = sectionElem?.querySelector('input[name="section-deleted[]"]') as HTMLInputElement|undefined;

        if(inputElem) {
            inputElem.value = '0';
            sectionElem?.classList.remove('deleted');
            this.saveForm.setModified(true);
        }
    }

    handleEditSectionTitleChange(e:Event) {
        if(!(e.target instanceof HTMLInputElement)) {
            return;
        }

        const titleElem = e.target.closest('.titletItem')?.querySelector('.title');
        if(!titleElem) {
            return;
        }

        titleElem.textContent = e.target.value;
    }

    handleEditProductClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        e.target.closest('.productItem')?.classList.add('editing');
    }

    handleSaveProductClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        e.target.closest('.productItem')?.classList.remove('editing');
    }

    handleDeleteProductClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        const productElem = e.target.closest('.productItem');
        const inputElem = productElem?.querySelector('input[name="product-deleted[]"]') as HTMLInputElement|undefined;

        if(inputElem) {
            inputElem.value = '1';
            productElem?.classList.add('deleted');
            this.saveForm.setModified(true);
        }
    }

    handleCancelDeleteProductClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        const productElem = e.target.closest('.productItem');
        const inputElem = productElem?.querySelector('input[name="product-deleted[]"]') as HTMLInputElement|undefined;

        if(inputElem) {
            inputElem.value = '0';
            productElem?.classList.remove('deleted');
            this.saveForm.setModified(true);
        }
    }

    handleProductHiddenChange(e:Event) {
        if(!(e.target instanceof HTMLInputElement)) return;
        
        let inputElem = e.target.parentElement?.querySelector('input[name="product-hidden[]"]') as HTMLInputElement|undefined;
        if(!inputElem) return;

        inputElem.value = e.target.checked ? '1' : '0';

        if(e.target.checked) {
            e.target.closest('.productItem')?.classList.add('productHidden');
        } else {
            e.target.closest('.productItem')?.classList.remove('productHidden');
        }
        this.saveForm.setModified(true);
    }

    handleProductNewChange(e:Event) {
        if(!(e.target instanceof HTMLInputElement)) return;
        
        let inputElem = e.target.parentElement?.querySelector('input[name="product-new[]"]') as HTMLInputElement|undefined;
        if(!inputElem) return;

        inputElem.value = e.target.checked ? '1' : '0';
        this.saveForm.setModified(true);
    }

    addSection() {
        let sectionHtml = this.sectionTemplate({
            index: document.querySelectorAll('.sectionItem').length,
        });

        let elem = document.createElement('template');
        elem.innerHTML = sectionHtml;
        let sectionElem = elem.content.firstElementChild;

        if(!sectionElem) {
            return;
        }

        sectionElem.querySelector('.btn-save-section')?.addEventListener('click', this.handleSaveSectionClick.bind(this));
        sectionElem.querySelector('.btn-delete-section')?.addEventListener('click', this.handleDeleteSectionClick.bind(this));
        sectionElem.querySelector('.cancelDeleted')?.addEventListener('click', this.handleCancelDeleteSectionClick.bind(this));
        sectionElem.querySelector('.btn-add-product')?.addEventListener('click', this.handleAddProductClick.bind(this));
        sectionElem.querySelector('.titletItem .edit')?.addEventListener('click', this.handleEditSectionClick.bind(this));
        sectionElem.querySelector('.titletItem input[name="section-name[]"]')?.addEventListener('change', this.handleEditSectionTitleChange.bind(this));
        this.initSortableProducts(sectionElem.querySelector('.listItems') || undefined);
        
        document.getElementById('listSections')?.appendChild(sectionElem);

        setTimeout(() => {
            (sectionElem?.querySelector('.titletItem .edit') as HTMLElement|undefined)?.click();
            (sectionElem?.querySelector('input[name="section-name[]"]') as HTMLInputElement|undefined)?.focus();
        });
    }

    handleAddSectionClick(e:Event) {
        this.addSection();
        this.saveForm.setModified(true);
    }

    addProduct(sectionElem:Element) {
        let productHtml = this.productTemplate({
            index: document.querySelectorAll('.productItem').length,
            section_index: sectionElem.querySelector('input[name="section-index[]"]')?.getAttribute('value'),
        });

        let elem = document.createElement('template');
        elem.innerHTML = productHtml;
        let productElem = elem.content.firstElementChild;

        if(!productElem) {
            return;
        }

        productElem.querySelectorAll('input').forEach(elem2 => elem2.addEventListener('click', this.handleEditProductClick.bind(this)));
        productElem.querySelector('.btn-save-product')?.addEventListener('click', this.handleSaveProductClick.bind(this));
        productElem.querySelector('.btn-delete-product')?.addEventListener('click', this.handleDeleteProductClick.bind(this));
        productElem.querySelector('.cancelDeleted')?.addEventListener('click', this.handleCancelDeleteProductClick.bind(this));
        productElem.querySelector('input[name="product-hidden-checkbox[]"]')?.addEventListener('change', this.handleProductHiddenChange.bind(this));
        productElem.querySelector('input[name="product-new-checkbox[]"]')?.addEventListener('change', this.handleProductNewChange.bind(this));
        FileUpload(productElem.querySelector('input[name="product-image[]') as HTMLInputElement);

        sectionElem.querySelector('.listItems')?.appendChild(productElem);

        setTimeout(() => {
            let inputElem = productElem?.querySelector('input[name="product-name[]"]') as HTMLInputElement|undefined;
            inputElem?.click();
            inputElem?.focus();
        });
    }

    handleAddProductClick(e:Event) {
        if(!(e.target instanceof Element)) return;
        let sectionElem = e.target.closest('.sectionItem');
        if(!sectionElem) {
            return;
        }

        this.addProduct(sectionElem);
        this.saveForm.setModified(true);
    }
}