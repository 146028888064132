import _ from 'underscore';

export class AdminSharing {
    init() {
        document.getElementById('btn-copy-url')?.addEventListener('click', (e) => {
            const inputElem = document.getElementById('share-url');

            if(!(inputElem instanceof HTMLInputElement)) return;

            inputElem.select();
            inputElem.setSelectionRange(0, 99999);

            if(navigator.permissions) {
                navigator.permissions.query({name: "clipboard-write"}).then((result) => {
                    if(result.state === "granted") {
                        navigator.clipboard.writeText(inputElem.value);
                    } else {
                        document.execCommand('copy');        
                    }
                });
            } else {
                document.execCommand('copy');
            }
        });
    }
}