export class Login {
    init() {
        document.getElementById('btn-show-login')?.addEventListener('click', () => {
            document.getElementById('signup-box')?.classList.remove('active');
            document.getElementById('login-box')?.classList.add('active');
        });

        document.getElementById('btn-show-signup')?.addEventListener('click', () => {
            document.getElementById('login-box')?.classList.remove('active');
            document.getElementById('signup-box')?.classList.add('active');
        });
    }
}