export class SaveForm {
    protected modified:boolean = false;
    protected $form?:Element

    constructor(formId:string) {
        //Prevent leaving without saving
        window.addEventListener('beforeunload', (e) => {
            if(!this.modified) return;

            e.preventDefault();
            //This doesn't work in most browsers anymore but we'll still keep it
            e.returnValue = "Assurez-vous d'enregistrer vos changements avant de continuer!";
        });

        this.$form = document.getElementById(formId) || undefined;
        if(!this.$form) return;
    
        //Reset the modified value before saving so we dont get the warning
        this.$form.addEventListener('submit', () => {
            this.setModified(false);
            return true;
        })

        //Auto-register all the input and textarea changes
        this.$form.querySelectorAll('input').forEach(elem => {
            elem.addEventListener('change', () => {
                this.setModified(true);
            })
        });
        this.$form.querySelectorAll('textarea').forEach(elem => {
            elem.addEventListener('change', () => {
                this.setModified(true);
            })
        });
    }

    public setModified(modified:boolean) {
        this.modified = modified;

        if(modified) {
            document.querySelector('button[type="submit"]')?.classList.add('should-save');
        } else {
            document.querySelector('button[type="submit"]')?.classList.remove('should-save');
        }
    }
}