import { FileUpload } from './FileUpload';
import { SaveForm } from './SaveForm';

export class AdminMenu {
    protected saveForm:SaveForm;

    init() {
        this.saveForm = new SaveForm('form-edit-menu');

        FileUpload(document.getElementById('menu-logo') as HTMLInputElement, () => this.saveForm.setModified(true));
        FileUpload(document.getElementById('menu-og-image') as HTMLInputElement, () => this.saveForm.setModified(true));
    }
}