import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";
import config from '../../config/config.json';
import Cookies from 'js-cookie'
import { AdminProducts } from './AdminProducts';
import { AdminSharing } from './AdminSharing';
import { AdminMenu } from './AdminMenu';
import { Login } from './Login';
import { AdminSettings } from './AdminSettings';

//Import all images
function importAll(r) {
    return r.keys().map(r);
}  
const images = importAll(require.context('../img', true, /\.(png|jpe?g|svg)$/));

if (config.environment == 'prod' || config.environment == 'staging') {
    Sentry.init({
      dsn: config.sentry.dsn,
      //release: config.sentry.release,
      environment: config.environment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
}

const onDomReady = (callBack: () => void) => {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callBack);
    }
    else {
      callBack();
    }
}

const popmoreClick = function(e:Event) {
    let $trigger = e.target as Element;
    $trigger.parentElement?.querySelector('.popmore')?.classList.toggle('moreopen');
};
onDomReady(() => {
    var listSelectedItems = new Map();
    var $cart = document.getElementById('cart');
    var $listItems = document.getElementById('list-items');

    //Init the list of items based on html
    $listItems?.querySelectorAll('.item').forEach((elem, i) => {
        elem.setAttribute('id', 'item-'+i);
        elem.querySelector('.popmore-trigger')?.addEventListener('click', popmoreClick);
    });

    //Grab cart from cookies 
    let cartCookie = Cookies.get('cart');

    if(cartCookie) {
        let arraySelectedItems = JSON.parse(cartCookie);

        if(arraySelectedItems instanceof Array) {
            listSelectedItems = new Map();
            arraySelectedItems.forEach((productId) => {
                listSelectedItems.set(productId, 1);
                document.getElementById(productId)?.classList.add('active');
            });
            updateCart();
        }
    }

    document.getElementById('btn-cart')?.addEventListener('click', function() {
        document.getElementById('cart-container')?.classList.add('open');
        document.getElementById('body')?.classList.add('overflow');
    });

    document.querySelectorAll('#btn-close-cart, #overlay').forEach(elem => {
        elem.addEventListener('click', function() {
            document.getElementById('cart-container')?.classList.remove('open');
            document.getElementById('body')?.classList.remove('overflow');
        })
    });

    $listItems?.querySelectorAll('.item .itemImg').forEach(elem => {
        elem.addEventListener('click', function() {
            let id = elem.parentElement?.getAttribute('id');
    
            if(elem.parentElement?.classList.contains('active')) {
                elem.parentElement?.classList.remove('active');
                listSelectedItems.delete(id);
            } else {
                elem.parentElement?.classList.add('active');
                listSelectedItems.set(id, 1);
            }
    
            updateCart();
        });
    });

    document.getElementById('btn-reset-cart')?.addEventListener('click', function() {
        listSelectedItems = new Map();
        updateCart();
    });

    function updateCart() {
        Cookies.set('cart', JSON.stringify(Array.from(listSelectedItems.keys())));

        if(!$cart) {
            return;
        }

        $cart.innerHTML = '';

        //Reset all active item
        $listItems?.querySelectorAll('.item').forEach(elem => {
            elem.classList.remove('active');
        });

        listSelectedItems.forEach((qty, productId) => {
            //Mark product item as selected
            document.getElementById(''+productId)?.classList.add('active');

            //Add cart item
            let $itemWrapper = document.getElementById(productId)?.parentElement?.cloneNode(true) as Element|undefined;

            if(!$itemWrapper) {
                return;
            }

            $itemWrapper.querySelectorAll('.item').forEach(elem => {
                elem.classList.remove('active');
                elem.setAttribute('id','');
                elem.querySelector('.popmore-trigger')?.addEventListener('click', popmoreClick);
            });

            $cart?.append($itemWrapper);
        });
    }

    const $body = document.getElementById('body');

    if($body?.classList.contains('page-auth-login')) {
        let page = new Login;
        page.init();
    }
    else if($body?.classList.contains('page-admin-products')) {
        let page = new AdminProducts;
        page.init();
    }
    else if($body?.classList.contains('page-admin-sharing')) {
        let page = new AdminSharing;
        page.init();
    }
    else if($body?.classList.contains('page-admin-admin')) {
        let page = new AdminMenu;
        page.init();
    }
    else if($body?.classList.contains('page-admin-settings')) {
        let page = new AdminSettings;
        page.init();
    }

    document.getElementById('btn-open-sidebar')?.addEventListener('click', (e) => {
        const sidebarElem = document.getElementById('sidebarMenu');

        if(!sidebarElem) return;

        if(sidebarElem.classList.contains('open')) {
            sidebarElem.classList.remove('open');
            $body?.classList.remove('overflowMenu');
        } else {
            sidebarElem.classList.add('open');
            $body?.classList.add('overflowMenu');
        }
    });
});