export function FileUpload(elem:HTMLInputElement, onChange?:() => void) {
    elem.addEventListener('change', (e) => {
        FileUpload.handleChange(e);
        onChange && onChange();
    });
    elem.parentElement?.querySelector('.removeImage')?.addEventListener('click', (e) => {
        let inputElem = elem.parentElement?.querySelector('.input-delete-image') as HTMLInputElement|undefined;
        if(!inputElem) return;
        
        inputElem.value = "1";

        const wrapElem = inputElem.closest('.inputWrap');
        wrapElem?.querySelector('.custom-file-wrapper')?.classList.remove('uploadedfile');

        elem.value = '';
        onChange && onChange();
    })
}

FileUpload.handleChange = (e:Event) => {
    const inputElem = e.target as HTMLInputElement;
    const wrapElem = inputElem.closest('.inputWrap');
    const file = inputElem.files?.item(0);

    //No file selected
    if(!file) {
        wrapElem?.querySelector('.custom-file-wrapper')?.classList.remove('uploadedfile');
        return;
    }

    //Set as uploaded
    wrapElem?.querySelector('.custom-file-wrapper')?.classList.add('uploadedfile');

    //Update text
    const textElem = wrapElem?.querySelector('.text.uploadfile');
    if(textElem) {
        textElem.textContent = file.name;
    }

    //Update image
    const imgElem = wrapElem?.querySelector('.image.uploadfile');
    if(imgElem instanceof HTMLElement) {
        imgElem.style.backgroundImage = 'url('+URL.createObjectURL(file)+')';
    }
}